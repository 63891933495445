import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PageLink } from "./link";
import BlockContent from "@sanity/block-content-to-react";

export const ParentPage = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ParentPageQuery {
      sanityParentGlobal {
        title
        _rawParentMenu(resolveReferences: { maxDepth: 10 })
        _rawParentAnnouncement(resolveReferences: { maxDepth: 10 })
      }
    }
  `);

  const {
    title,
    _rawParentMenu,
    _rawParentAnnouncement,
  } = data.sanityParentGlobal;

  return (
    <div className="border-b border-evergreen mb-2 w-full">
      {_rawParentAnnouncement && (
        <div className="bg-lapis text-white px-2 py-2 font-sans text-sm c-single-line c-dark-bg text-center">
          <BlockContent blocks={_rawParentAnnouncement} />
        </div>
      )}
      <div className="flex">
        <div className="bg-white border-r border-evergreen w-64">
          <div className="sticky top-dnav">
            <div className="p-4 border-b border-t">
              <h3>{title}</h3>
            </div>
            <ul className="p-4">
              {_rawParentMenu.items.map((data) => {
                const { title, link, _type } = data;
                return (
                  <li key={title}>
                    <PageLink type={_type} to={link} className="pb-3 block">
                      {title}
                    </PageLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="p-8 flex-1">{children}</div>
      </div>
    </div>
  );
};
