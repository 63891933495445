import React from "react";
import { graphql } from "gatsby";
import { Pagination } from "../components/pagination";
import { ParentPage } from "../components/parentPage";
import { FileLink } from "../components/link";

const ParentForm = ({
  data,
  pageContext,
}: {
  pageContext: {
    limit: number;
    skip: number;
    prev?: string;
    next?: string;
  };
  data: {
    allSanityParentForm: {
      edges: [];
    };
  };
}) => (
  <ParentPage>
    <div className="max-w-4xl">
      <h1 className="mb-6 mt-4">Forms</h1>
      {data.allSanityParentForm.edges.map((edge, i) => (
        <FileLink key={i} file={edge.node._rawForm} title={edge.node.title} />
      ))}
      <Pagination {...pageContext} />
    </div>
  </ParentPage>
);

export const query = graphql`
  query sanityParentFormQuery($skip: Int!, $limit: Int!) {
    allSanityParentForm(limit: $limit, skip: $skip) {
      edges {
        node {
          _rawForm(resolveReferences: { maxDepth: 10 })
          title
        }
      }
    }
  }
`;

export default ParentForm;
